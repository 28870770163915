import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import styled from 'styled-components'
import { Row, Col, Container } from 'react-bootstrap'
import { Box } from '../Core'
import Link from '../Link'
import { useStaticQuery, graphql } from 'gatsby'
import AppStore from '../../assets/image/svg/appstore-white.inline.svg'
import PlayStore from '../../assets/image/svg/playstore-white.inline.svg'
import NewTabIcon from '../../assets/image/svg/ic_newtab16.inline.svg'
import { useTranslation } from 'react-i18next'
import theme from '../../utils/theme'


const Logo = loadable(() => import('../Logo'))
const LangSelector = loadable(() => import('../Header/LangSelector'))

const UlStyled = styled.ul`
  margin: 0;
  margin-bottom: 0.75em;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 0.75em;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      font-size: 16px;
      line-height: 32px;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.primary} !important;
      }
    }
  }
`

const UlStyledApps = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    float: left;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      font-size: 16px;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.primary} !important;
      }
    }
  }
`

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #444444 ` : `1px solid ${theme.colors.border}`};

  padding: 24px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.lightShade : theme.colors.darkShade} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 12px;
      margin: 0;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.primary} !important;
      }
    }
  }
  a {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade} !important;
    text-decoration: underline;
    &:visited {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      color: ${({ theme, color }) => theme.colors.primary} !important;
    }
  }
`

// const ImgStyled = styled.img`
//     filter: ${(props) => props.userDarkMode ? `invert(40%)` : `invert(50%)` };
//     margin-bottom: 4px;
//   `;

const Footer = (props) => {
  const { t, i18n, ready } = useTranslation(['landing', 'app'], { useSuspense: false });

  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const [windowWidth, setWindowWidth] = useState()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setWindowWidth(window.innerWidth)
      window.onresize = () => {
        setWindowWidth(window.innerWidth)
      }
    }
  }, [])

  if (props.footerType === 'comingSoon') {
    return (
      <Box role="contentinfo" bg={userDarkModeSelector ? 'black' : 'bg'}>
        <div className="p-5">
          <div className="pb-5">
            <Logo className="logo-small" white={userDarkModeSelector} />
          </div>
          <CopyRightArea theme={theme} dark={userDarkModeSelector}>
            <Row>
              <Col lg="10" md="9" sm="9" xs="9" className="mb-4">
                <Box className="d-flex flex-wrap">
                  <p className="mr-1">{t('copyrights')}</p>
                </Box>
              </Col>
              <Col lg="2" md="3" sm="3" xs="3" className="text-right mb-4">
                <ul className="social-icons">
                  <li>
                    <a
                      href="https://twitter.com/LightspdCrypto"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Twitter @LightspdCrypto"
                    >
                      <i className="icon icon-logo-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/lightspeedcrypto"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="LinkedIn @lightspeedcrypto"
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/lightspeedcrypto"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook @lightspeedcrypto"
                    >
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/lightspeedcrypto"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook @lightspeedcrypto"
                    >
                      <i className="icon icon-logo-instagram"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </div>
      </Box>
    )
  }

  return ready && (
    <>
      {/* <!-- Footer section --> */}
      <Box className="pt-5" role="contentinfo" bg={userDarkMode ? 'black' : 'bg'}>
        <Container>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col>
              <Logo className="logo-small" white={userDarkMode} />
            </Col>
            <Col xs="12" sm="12" lg="3" xl="2">
              <LangSelector white={userDarkMode} />
            </Col>
          </Row>
          <Row className="mt-5 mb-5" style={{ justifyContent: 'space-between' }}>
            <Col xs="6" sm="6" lg="3">
              <UlStyled color={userDarkMode ? 'lightShade' : 'darkShade'}>
                <li>
                  <Link to={`/${lang}/why-us`}>{t('whyUs')}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/platform`}>{t('tradingPlatform')}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/portfolio-management`}>
                    {t('portfolio')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/supported-exchanges/`}>
                    {t('supportedExchs')}
                  </Link>
                </li>
              </UlStyled>
            </Col>
            <Col xs="6" sm="6" lg="3">
              <UlStyled color={userDarkMode ? 'lightShade' : 'darkShade'}>
                <li>
                  <Link to={`/${lang}/security`}>{t('security')}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/pricing/`}>{t('pricing')}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/support/`}>{t('support')}</Link>
                </li>
                <li>
                  <a
                    aria-label={t('historicalData')}
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://www.coinigy.com/bitcoin-data/"
                  >
                    {t('historicalData')}{' '}
                    <NewTabIcon
                      style={{
                        filter: userDarkMode ? 'invert(40%)' : 'invert(50%)',
                        marginBottom: '4px',
                        marginLeft: '4px',
                        width: '16px',
                        height: '16px',
                      }}
                      aria-label={t('newTab')}
                      role="img"
                    />
                  </a>
                </li>
              </UlStyled>
            </Col>

            <Col xs="6" sm="6" lg="3">
              <UlStyled color={userDarkMode ? 'lightShade' : 'darkShade'}>
                <li>
                  <Link to={`/${lang}/learning/`}>{t('learning')}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/cryptocurrencies/`}>
                    {t('cryptocurrencies')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/markets/`}>{t('markets')}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/exchanges/`}>
                    {t('exchanges')}
                  </Link>
                </li>
              </UlStyled>
            </Col>
            <Col xs="6" sm="6" lg="3">
              <UlStyled color={userDarkMode ? 'lightShade' : 'darkShade'}>
                <li>
                  <Link to={`/${lang}/about/`}>{t('aboutUs')}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/about/leadership`}>
                    {t('leadership')}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.lightspeed.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Lightspeed Financial
                    <NewTabIcon
                      style={{
                        filter: userDarkMode ? 'invert(40%)' : 'invert(50%)',
                        marginBottom: '4px',
                        marginLeft: '4px',
                        width: '16px',
                        height: '16px',
                      }}
                      aria-label={t('newTab')}
                      role="img"
                    />
                  </a>
                </li>
              </UlStyled>
            </Col>
            {windowWidth < 991 ? (
              <Col
                xl="2"
                lg="12"
                className="d-flex justify-content-center mt-5 mt-xl-3"
              >
                <UlStyledApps color={userDarkMode ? 'lightShade' : 'darkShade'}>
                  <li className="mb-3 mr-3">
                    <a
                      aria-label="Appstore"
                      href="https://apps.apple.com/us/app/lightspeed-crypto/id6443884604"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AppStore
                        width="120"
                        height="40"
                        style={{
                          filter: userDarkMode ? `invert(40%)` : `invert(50%)`,
                          marginBottom: '4px',
                        }}
                        aria-label={t('appStoreImg')}
                        role="img"
                      />
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      aria-label="Playstore"
                      href="https://play.google.com/store/apps/details?id=com.lightspeedcrypto"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mb-5 mr-2"
                    >
                      <PlayStore
                        width="135"
                        height="40"
                        style={{
                          filter: userDarkMode ? `invert(40%)` : `invert(50%)`,
                          marginBottom: '4px',
                        }}
                        aria-label={t('googlePlayImg')}
                        role="img"
                      />
                    </a>
                  </li>
                </UlStyledApps>
              </Col>
            ) : null}
          </Row>
          <CopyRightArea dark={userDarkMode}>
            <Row>
              <Col className="mb-4 pt-4 mb-sm-2 text-center">
                <ul className="social-icons mb-4">
                  <li>
                    <a
                      href="https://twitter.com/LightspdCrypto"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Twitter @LightspdCrypto"
                    >
                      <i className="icon icon-logo-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/lightspeedcrypto"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="LinkedIn @lightspeedcrypto"
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/lightspeedcrypto"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook @lightspeedcrypto"
                    >
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/lightspeedcrypto"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Instagram @lightspeedcrypto"
                    >
                      <i className="icon icon-logo-instagram"></i>
                    </a>
                  </li>
                </ul>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://insights.coinigy.com/tag/changelog/"
                  >
                    {process.env.GIT_TAG}
                  </a> {t('copyrights')}
                  <span role="presentation"> · </span>
                  <Link to={`/${lang}/terms`}>{t('terms')}</Link>
                  <span role="presentation"> · </span>
                  <Link to={`/${lang}/privacy/`}>{t('privacy')}</Link>
                  <span role="presentation"> · </span>
                  <Link to={`/${lang}/security/security-practices/`}>
                    {t('securityPractices')}
                  </Link>
                  <span role="presentation"> · </span>
                  <Link to={`/${lang}/supporting-documents/`}>
                    {t('supportingDocs')}
                  </Link>
                </p>
                <p className="my-2">{t('disclaimer')}</p>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  )
}

export default Footer
